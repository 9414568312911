import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import Container from "../containers/Container";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <Container>
      <Wrapper>
        <div>
          <h1>404</h1>
          <h3>Sorry! Page Not Found</h3>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate("/")}
          >
            back home
          </Button>
        </div>
      </Wrapper>
    </Container>
  );
}
const Wrapper = styled.div`
  min-height: 100vh;
  max-width: 424px;
  display: grid;
  place-items: center;
  text-align: center;
  h1 {
    font-size: 10rem;
  }
  h3 {
    margin-bottom: 1.5rem;
  }
`;
