import React from "react";
import styled from "styled-components";
import ProductPhoto from "../assets/images/product.png";
import plus from "../assets/images/Group 116.png";
import minus from "../assets/images/Group 117.svg";
import trash from "../assets/images/trash.svg";
import { useCartContext } from "../context/CartContext";
import { convertToBanglaNumber } from "../helpers/convert";
import { useAppContext } from "../context/AppContext";

function CartItem({
  id,
  image_url,
  name,
  price,
  amount,
  unit,
  unit_amount,
  unit_type,
  mrp,
}) {
  const { cartItems, removeFromCart, increase, decrease } = useCartContext();
  const {lang} = useAppContext();
  return (
    <Wrapper>
      <div className="item">
        <ContentDiv>
          <img src={image_url || ProductPhoto} width='60' height='60' />
          <div>
            <Price>{lang?.checkedA ? '৳'+convertToBanglaNumber(mrp.toFixed(2)) : mrp.toFixed(2)+' Tk'}</Price>
            <Quantity>{name}</Quantity>
            <Weight>
            {unit_amount * amount} {unit} {unit_type && "/ " + unit_type}
            </Weight>
          </div>
        </ContentDiv>
        <div className="addToCart">
          <div onClick={() => increase(id)} className="plus">
            +
          </div>
          <div className="amount">{amount}</div>
          <div onClick={() => decrease(id)} className="plus">
            -
          </div>
        </div>
        <div className="remove" onClick={() => removeFromCart(id)}>
          <img src={trash} alt="trash" width='40' height='50' />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: #fff;
  width: 100%;
  padding: 10px;
  border-radius: 16px;
  box-sizing: border-box;
  margin: 8px 0;
  border: 1px solid #e6e6e6;
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
      margin-right: 15px;
    }
  }

  .addToCart {
    text-align: center;
    margin-left: auto;
    .plus {
      font-size: 16px;
      color: #2c14dd;
      cursor: pointer;
    }
    .amount {
      padding: 1px 10px;
      border-radius: 16px;
      color: #868889;
      margin: 5px;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .remove {
    cursor: pointer;
    transition: all 0.3s linear ease-in;
  }
  /* &:hover .remove{
    display: block;
  } */
`;

const ContentDiv = styled.div`
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90px;
`;
const Price = styled.p`
  margin: 0;
  font-size: 14px;
  color: #2c14dd;
`;
const Quantity = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #000;
`;
const Weight = styled.p`
  margin: 0;
  font-size: 12px;
  color: #868889;
`;
export default CartItem;
