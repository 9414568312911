import React, { useEffect, useRef } from "react";
import styled from "styled-components";

export default function ({ setNumber,value,isDisabled,numberError }) {
  const handleKeyDown = e => e.target.value = e.target.value.match(/^([^e+-]{0,15})/)[0]
  const inputRef = useRef(null);
  useEffect(()=>{
    inputRef.current.focus();
  },[])
  return (
    <Wrapper>
      <div className={`number ${numberError && 'error' }`}>
        <input type="text"
        ref={inputRef}
        onInput={e=>handleKeyDown(e)} 
        value={value} 
        onChange={(e) => setNumber(e.target.value)} 
        disabled={isDisabled}/>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: flex;
  width: 95%;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #F5F4F5;
  // margin: 30px auto;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  margin-top: 5px;

  // .code {
  //   border-right: thin solid #c4c4c4;
  //   height: 61px;
  //   width: 81px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   font-size: 15px;
  //   font-weight: 500;
  //   padding: 0 5px;
  // }
  .number {
    height: 61px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: right;
  }
  .error{
    border: 2px solid red;
  }
  input {
    width: 100%;
    border: 0;
    outline: 0;
    text-align: center;
    background: transparent;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 2px;
  }
  input[type="number"] {
    text-align: left;
    padding-left: 20px;
    
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
