import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuthContext } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import ButtonBlock from "../../components/Buttons/ButtonBlock";
import StoreFront from "../../assets/images/s1.PNG";
import { toast } from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import TelInput from "../../components/CustomInputs/TelInput";
import { apiSecret } from "../../configs/configs";
import Topbar from "../../components/Topbar";
import { useTranslation } from "react-i18next";
import { banglaLoginErrorMessage } from "../../helpers/convert";
import { useAppContext } from "../../context/AppContext";

function UserRegistration() {
  const { setOtpTimeLimit, number, setNumber } = useAuthContext();
  const { lang } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const sendOTP = async (e) => {
    e.preventDefault();
      try {
        setLoading(true);
        const { data } = await apiSecret.post("/auth/send-otp", {
          msisdn:number,
        });
        setLoading(false);
        if (data.status === "SUCCESS") {
          localStorage.setItem(
            "otp_token",
            JSON.stringify(data?.data?.otp_token)
          );
          setOtpTimeLimit(Number(data?.data?.validation_time));
          localStorage.setItem(
            "validation_time",
            Number(data?.data?.validation_time)
          );
          setError(false);
          navigate("/verify-otp");
          toast.dismiss();
          toast.success(data?.message);
        }
      } catch (error) {
        setLoading(false);
        let error_message = error?.response?.data?.message;
        (error_message?.startsWith("MSISDN") ||
          error_message?.startsWith("Invalid")) &&
          setError(true);
        error_message = banglaLoginErrorMessage(error_message, lang);
        if(error_message){
          toast.dismiss();
          toast.error(error_message);
        }
        else{
          toast.dismiss();
          toast.error(error?.response?.data?.message);
        }
      }
  };

  useEffect(() => {
    setError(false);
  }, [number]);
  return (
    <Wrapper>
      <Topbar>{t("registration_title")}</Topbar>
      <UpperPart>
        <div className="img-container">
          <img src={StoreFront} height='100%' width='100%' alt="" />
        </div>
      </UpperPart>
      <SendOTP>
        <h2>{t("registration_title")}</h2>
        <p>{t("registration_subtitle")}</p>
        <form onSubmit={sendOTP}>
          <TelInput setNumber={setNumber} value={number} numberError={error} />
          <ButtonBlock
            onClick={sendOTP}
            type="submit"
            disabled={loading && true}
            isDisabled={(loading || error || !number) && true}
          >
            {loading ? (
              <CircularProgress color="primary" />
            ) : (
              t("register_button")
            )}
          </ButtonBlock>{" "}
          <br />
        </form>
        <p style={{ textAlign: "center" }}>
          {t("already_registered")} <Link to="/login">{t("login_title")}</Link>
        </p>
      </SendOTP>
    </Wrapper>
  );
}
const UpperPart = styled.div`
  .img-container {
    width: 100%;
    height: 320px;
    padding: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
`;

const SendOTP = styled.div`
  line-height: 25px;
  /* background-color: #f4f5f9; */
  max-width: 424px;
  padding: 15px;
  /* z-index: 9999;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3%; */
  border-radius: 10px 10px 0 0;

  h2 {
    font-size: 25px;
  }
  p {
    color: #868889;
  }
  input {
    margin: 20px auto;
    font-size: 15px;
  }
`;
const Wrapper = styled.div``;

export default UserRegistration;
