import React, { useEffect, useState } from "react";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import styled from "styled-components";
import ButtonBlock from "../../components/Buttons/ButtonBlock";
import {useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import OrderList from "../../components/Products/OrderList";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useAuthContext } from "../../context/AuthContext";
import { useCartContext } from "../../context/CartContext";
import { useTranslation } from "react-i18next";

function Order() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const {t} = useTranslation();
  const { customerLoginInfo,bpLoginInfo } = useAuthContext();
  const { setCartItems } = useCartContext();
  const [orderInfo, setOrderInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setCartItems([]);
    navigate("/products");
  };


  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getOrderInfo = async () => {
      try {
        setLoading(true);
        const access_token = customerLoginInfo?.token?.access_token || bpLoginInfo?.token?.access_token;

        const { data } = await axiosPrivate(
          `/orders/get-latest`,
          {
            signal: controller.signal,
          },
          { headers: { Authorization: `Bearer ${access_token}` } }
        );
        isMounted && setOrderInfo(data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.response.data);
      }
    };
    getOrderInfo();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleBack = () =>{
    setCartItems([]);
    navigate('/products');
  }
  return (
    <Wrapper>
      <Header>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="backbutton"
            onClick={handleBack}
            style={{ marginRight: "10px", cursor: "pointer" }}
          >
            <KeyboardBackspaceIcon />
          </div>
          <h3>Cart</h3>
        </div>
      </Header>
      <CartContainer>
        <div>
          <h3>{t('order_no')} - {orderInfo?.order_no}</h3>
          <ShoppingBagIcon />
          <div className="text">
            <h3>{t('order_success_message')}</h3>
            <p>{t('order_success_sub_message')}</p>
          </div>
        <OrderList loading={loading} orderInfo={orderInfo} />
        </div>
      </CartContainer>

      <form onSubmit={handleSubmit}>
        <ButtonBlock>{t('back_home_button_text')}</ButtonBlock>
      </form>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  .empty-cart {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 20px 10px;
`;

const CartContainer = styled.section`
  min-height: 70vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  svg {
    height: 135px;
    width: 135px;
    color: #8f28b5;
    padding: 10px;
  }
  .text {
    p {
      color: #868889;
      margin: 10px 0;
    }
  }
`;

export default Order;
