import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { convertToBanglaNumber } from "../../helpers/convert";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";
import { useCartContext } from "../../context/CartContext";

export default function OrderList({loading,orderInfo}) {
  const {lang} = useAppContext();
  const {cartTotal,discountedAmount} = useCartContext();
  const {t} = useTranslation();

  if (loading) {
    return <Skeleton variant="rectangular" width={424} height={500} />;
  }

  return (
    <Wrapper>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          mt: 5,
          boxShadow: "none",
        }}
      >
        <TableContainer>
          <Table>
            <TableHead style={{ borderBottom: "0" }}>
              <TableRow>
                <TableCell
                  align="center"
                  style={{ minWidth: 140, borderBottom: "1px solid #FFC2BE" }}
                >
                  {t('product_name')}
                </TableCell>

                <TableCell
                  align="center"
                  style={{ minWidth: 80, borderBottom: "1px solid #FFC2BE" }}
                >
                    {t('product_amount')}
                </TableCell>

                <TableCell
                  align="center"
                  style={{ minWidth: 80, borderBottom: "1px solid #FFC2BE" }}
                >
                    {t('product_price')}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {orderInfo?.orderItems?.map((orderItem) => (
                <TableRow key={orderItem.id}>
                  <TableCell sx={{ borderBottom: "none" }}>
                    {orderItem.product.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderBottom: "none",
                      fontWeight: "600",
                    }}
                  >
                    x {lang?.checkedA ? convertToBanglaNumber(orderItem.quantity) : orderItem.quantity}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "right",
                      borderBottom: "none",
                      fontWeight: "600",
                    }}
                  >
                    {lang?.checkedA ? convertToBanglaNumber(orderItem?.total_price?.toFixed(2)) : orderItem?.total_price?.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}

              <TableRow style={{borderTop: "1px solid #FFC2BE"}} >
                <TableCell sx={{ borderBottom: "none", fontWeight: "600", }}>
                {t('sub_total')}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    borderBottom: "none",
                    fontWeight: "500",
                  }}
                >
                  
                </TableCell>
                
                <TableCell
                  sx={{
                    textAlign: "right",
                    borderBottom: "none",
                    fontWeight: "600",
                  }}
                >
                  {lang?.checkedA ?  convertToBanglaNumber(+cartTotal?.toFixed(2)) : cartTotal?.toFixed(2)}
                </TableCell>
              </TableRow>


              <TableRow>
                <TableCell sx={{ borderBottom: "none", fontWeight: "600", }}>
                {t('discount')}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    borderBottom: "none",
                    fontWeight: "500",
                    fontSize:"15px"
                  }}
                >
                  
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "right",
                    borderBottom: "none",
                    fontWeight: "500",
                    fontSize:"15px"
                  }}
                >
                  {lang?.checkedA ?  '-'+convertToBanglaNumber(+discountedAmount?.toFixed(2)) : '-'+discountedAmount?.toFixed(2)}
                </TableCell>
              </TableRow>

              <TableRow style={{borderTop: "2px solid #FFC2BE"}} >
                <TableCell sx={{ borderBottom: "none", fontWeight: "600", }}>
                {t('total')}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    borderBottom: "none",
                    fontWeight: "600",
                  }}
                >
                  
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "right",
                    borderBottom: "none",
                    fontWeight: "600",
                  }}
                >
                  {lang?.checkedA ?  convertToBanglaNumber(+orderInfo?.grand_total?.toFixed(2)) : orderInfo?.grand_total?.toFixed(2)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin: 15px 0;

  hr {
    border: 0.5px solid #db4d45;
    margin: 2px;
  }

  .border {
    padding: 10px;
  }

  @media only screen and (max-width: 320px) {
  }
`;
