import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInputs/CustomInput";
import { toast } from "react-hot-toast";
import { useAuthContext } from "../../context/AuthContext";
import { apiSecret, baseAxios } from "../../configs/configs";
import Topbar from "../../components/Topbar";
import DropdownInput from "../../components/CustomInputs/Dropdown";
import ButtonBlock from "../../components/Buttons/ButtonBlock";
import { useTranslation } from "react-i18next";

function RegistrationComplete(props) {
  const navigate = useNavigate();
  const [pin, setPin] = useState("");
  const [confirm_pin, setConfirmPin] = useState("");
  const [companies, setCompanies] = useState([]);
  const [isChecked, setIsCheked] = useState(false);
  const [genders, setGenders] = useState([]);
  const {t} = useTranslation();
  const [regInfo, setRegInfo] = useState({
    name: "",
    emp_id: "",
    company_id: "",
    gender: "",
  });
  const { setCustomerLoginInfo, number } = useAuthContext();

  const handleChange = (e) => {
    setRegInfo((preveState) => ({
      ...preveState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleRegistrationSubmit = async (e) => {
    e.preventDefault();
    try {
      if (pin !== confirm_pin || !pin) {
        toast.dismiss();
        toast.error(t('pin_not_matched'));
      } else {
        const payload = {
          ...(regInfo?.company_id && { company_id: regInfo?.company_id }),
          ...(regInfo?.emp_id && { emp_id: regInfo?.emp_id }),
          ...(regInfo?.name && { name: regInfo?.name }),
          ...(regInfo?.gender && { gender: regInfo?.gender }),
        };


        const { data } = await baseAxios.post(
          "/auth/set-pin",
          { ...payload, pin, confirm_pin },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("one_time_token")
              )}`,
            },
          }
        );


        if (data.status === "SUCCESS") {
          setCustomerLoginInfo({ ...data?.data.customer, pin });
          localStorage.setItem(
            "customerInfo",
            JSON.stringify({ ...data?.data.customer, pin })
          );

          //-----login API call-------
          const res = await apiSecret.post(`/auth/login-with-pin`, {
            msisdn: number,
            pin,
          });
          localStorage.setItem(
            "customerLoginInfo",
            JSON.stringify(res?.data?.data)
          );
          setCustomerLoginInfo(res?.data?.data);
          //-----login API end--------

          navigate("/products");
          toast.dismiss();
          toast.success(data?.data?.customer?.is_hr_verified == 1 ? t('verifiedUser') : data.message);
        } else {
          //other page
        }
      }
    } catch (error) {

      toast.dismiss();
      toast.error(error.response.data.message);
    }
  };

  const getAllCompanies = async () => {
    const { data } = await baseAxios.get("/company/get-all", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem("one_time_token")
        )}`,
      },
    });

    setCompanies(data?.data);
  };
  const getAllGenders = async () => {
    const { data } = await baseAxios.get("/misc/get-genders", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem("one_time_token")
        )}`,
      },
    });

    setGenders(data?.data);
  };

  useEffect(() => {
    getAllCompanies();
    getAllGenders();
  }, []);

  return (
    <Wrapper>
      <Topbar>{t('registration_title')}</Topbar>
      <Heading>
        <h2>{t('welcome')}</h2>
        <p>{t('welcome_reg_subtitle')}</p>
      </Heading>
      <form onSubmit={handleRegistrationSubmit}>
        <CustomInput
          label={t('reg_phone')}
          value={JSON.parse(localStorage.getItem('msisdn'))}
          onChange={handleChange}
          name="msisdn"
          disabled={true}
        />
        <CustomInput
          label={t('reg_name')}
          name="name"
          value={regInfo?.name}
          onChange={handleChange}
        />
        <CustomInput
          label={t('reg_id')}
          name="emp_id"
          value={regInfo?.emp_id}
          onChange={handleChange}
        />
        <div className="dropdownInput">
          <DropdownInput
            label={t('reg_factory')}
            name="company_id"
            value={regInfo?.company_id}
            onChange={handleChange}
            options={companies}
          />{" "}
          &nbsp;
          <DropdownInput
            label={t('reg_gender')}
            name="gender"
            value={regInfo?.gender}
            onChange={handleChange}
            options={genders}
          />
        </div>
        <CustomInput
          label={t('reg_pin')}
          placeholder="****"
          value={pin}
          type="number" 
          name="pin"
          onChange={(e) => setPin(e.target.value)}
        />
        <CustomInput
          label={t('reg_pin_confirm')}
          placeholder="****"
          value={confirm_pin}
          type="number" 
          name="confirm_pin"
          onChange={(e) => setConfirmPin(e.target.value)}
        />
        <div className="confirmation_box">
          <div>
            <input
              type="checkbox"
              onChange={(e) => setIsCheked(e.target.checked)}
            />
          </div>
          <p>
            {t('reg_confirmation')}
          </p>
        </div>
        <div style={{ textAlign: "center", margin: "10px auto" }}>
          <ButtonBlock isDisabled={!isChecked}>{t('registration_title')}</ButtonBlock>
        </div>
      </form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  line-height: 25px;
  /* background-color: #f4f5f9; */
  max-width: 424px;
  padding: 15px;
  /* z-index: 9999;
  position: absolute;
  left: 0;
  right: 0;
  top: 0; */
  padding: 15px;
  .dropdownInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .confirmation_box {
    display: flex;
    margin: 16px auto;
    input[type="checkbox"] {
      cursor: pointer;
      outline: none;
      margin-right: 5px;
    }
    span {
      color: #2c14dd;
    }
  }
`;
const Heading = styled.section`
  line-height: 30px;
  margin: 16px 0;
  h2 {
    color: #240f51;
  }
  p {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

export default RegistrationComplete;
