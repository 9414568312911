import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination, Autoplay, Navigation, EffectFade } from 'swiper/modules';
import SliderLogo from '../../../assets/images/agros.svg';
import styled from 'styled-components';
import { useAppContext } from '../../../context/AppContext';
SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade]);


function OfferSlider() {
  const { carousel } = useAppContext();
  const dashboardImages = carousel?.filter(s => s?.app_location?.toLowerCase() === 'dashboard' && s?.for_application?.toLowerCase()=== "customer_app")

  return (
    <SliderWrapper>
      <Swiper
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        slidesPerView={'1'}
        pagination={{
          clickable: true,
        }}
        effect='slide'
        loop
        className="mySwiper"
      >
        {
          dashboardImages?.map(({ image_url, short_desc, icon }, idx) => <SwiperSlide key={idx}>
            <img src={image_url} alt='' width='100%' height='220' />
           {short_desc && <SliderContent className="slider-content">
              {icon && <img src={icon} alt='' height='35' width='35' />}
              <h1> {short_desc}</h1>
            </SliderContent>}
          </SwiperSlide>)
        }


      </Swiper>
    </SliderWrapper>
  );
}
const SliderWrapper = styled.div`
  height: 220px;
  border-radius: 16px;
    margin-top: 20px;
  .swiper-slide {
    overflow: hidden;
  }
  .swiper-slide img {
    border-radius: 16px;
    height: initial;
    height: 220px;
    width: 100%;
    object-fit: cover;
    /* height: initial; */
  }
  .swiper-horizontal > .swiper-pagination-bullets {
    bottom: 2% !important;
    color: #FFFFFF;
  }
  .swiper-pagination-bullet-active {
    background: rgba(108, 197, 29, 1);
  }
`

const SliderContent = styled.div`
  z-index: 9;
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 76px;
  text-align: center;
  background:linear-gradient(to bottom, #0A070B, #0A070B);
  border-radius: 16px;
  opacity: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 22px;
    color: #FFFFFF;
    font-weight: 500;
    max-width: 250px;
    padding: 5px;
  }
  img {
    width: 35px !important;
    height: 35px !important;
    position: absolute;
    left: 7%;
  }
  p {
    font-size: 15px;
    color: #868889;
    max-width: 300px;
    margin: 20px auto;
    position: relative;

  }
`
export default OfferSlider;