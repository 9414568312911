import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { apiSecret } from "../configs/configs";
import ButtonBlock from "../components/Buttons/ButtonBlock";
import { toast } from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import TelInput from "../components/CustomInputs/TelInput";
import UIdInput from "../components/CustomInputs/UIdnput";
import OtpBox from "../components/OtpBox";
import Topbar from "../components/Topbar";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../context/AppContext";
import { banglaLoginErrorMessage } from "../helpers/convert";
import Carousel from "react-material-ui-carousel";
import LoginSwitchPage from "./../components/Buttons/LoginSwitchButton";
import "./LoginPageStyle.css";

function LoginPage() {
  const navigate = useNavigate();
  const { customerLoginInfo, setCustomerLoginInfo, setNumber, number } =
    useAuthContext();
  const { lang, carousel } = useAppContext();
  const { t } = useTranslation();
  const [userId, setUserId] = useState("");
  const [pin, setPin] = useState("");
  const [numberError, setNumberError] = useState(false);
  const [pinError, setPinError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const carousels = carousel?.filter(
    (s) => s?.app_location?.toLowerCase() === "login_page" && s?.for_application?.toLowerCase()=== "customer_app"
  );
  //for toggle switch selection
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (isChecked) {
      setPayload({ shera_id: userId, pin });
    } else {
      setPayload({ msisdn: number, pin });
    }
  }, [isChecked, number, userId, pin]);

  const handleSwitchChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
     
      if(isChecked && userId.length !== 7){
        toast.dismiss();
        toast.error(t('shera_id_length'));
        return;
      }
      setLoading(true);
      const { data } = await apiSecret.post(`/auth/login-with-pin`, payload);
      setLoading(false);

      if (data?.status === "SUCCESS") {
        localStorage.setItem("customerLoginInfo", JSON.stringify(data?.data));
        setCustomerLoginInfo(data?.data);
        setNumberError(false);
        setPinError(false);
        navigate("/products");
        const login_success_message = lang.checkedA
          ? "লগইন সফল হয়েছে"
          : data.message;
        toast.success(login_success_message);
      }
    } catch (error) {
      setLoading(false);
      let error_message = error?.response?.data?.message;
      error_message?.startsWith("MSISDN") ||
      error_message?.startsWith("Invalid Phone") || error_message?.startsWith("Invalid ID")
        ? setNumberError(true)
        : setPinError(true);

      error_message = banglaLoginErrorMessage(error_message, lang);
      if(error_message){
        toast.dismiss();
        toast.error(error_message);
      }
      else{
        toast.dismiss();
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    // const regex = /^1[3-9]\d{8}$/i;
    setNumberError(false);
    setPinError(false);
  }, [number, pin]);

  useEffect(() => {
    if (customerLoginInfo?.customer?.has_logged_in) {
      navigate("/products");
    }
  }, [customerLoginInfo]);

  return (
    <>
      <Wrapper>
        <Topbar>{t("login_title")}</Topbar>
        <UpperPart>
          <Carousel
            animation="slide"
            indicators={carousels?.length < 1 && false}
            navButtonsAlwaysVisible={false}
            navButtonsProps={{
              style: {
                display: "none",
                opacity: 0,
              },
            }}
          >
            {carousels?.map((c, idx) => (
              <div className="img-container" key={idx}>
                <img src={c["image_url"]} alt="" height="100%" width="100%" />
              </div>
            ))}
          </Carousel>
        </UpperPart>
        <LoginPart className="loginHeadings">
          <h1>{t("login_title")}</h1>
          {/* <p>{t("login_subtitle")}</p> */}

          {/* <LoginSwitchPage/> */}
          {/* -----------the toggle switch----------- */}

            <div className="middle">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleSwitchChange}
                />
                <span className="slider" />
              </label>
            </div>


          <div className="Border">
            <form onSubmit={handleLogin}>
              {isChecked ? (
                <div>
                  <p>{t('enter_uid_label')}</p>
                  <UIdInput
                    setNumber={setUserId}
                    // numberError={numberError}
                    value={userId}
                  />
                </div>
              ) : (
                <div>
                  <p>{t('enter_msisdn_label')}</p>
                  <TelInput
                    setNumber={setNumber}
                    numberError={numberError}
                    value={number}
                  />
                </div>
              )}

              <p>{t('enter_password_label')}</p>
              <OtpBox
                otpClass="otp-area"
                otp={pin}
                setOtp={setPin}
                pinError={pinError}
                length={4}
                gap={4}
              />
              <ButtonBlock
                type="submit"
                isDisabled={(loading || !pin) && true}
              >
                {loading ? (
                  <CircularProgress color="primary" />
                ) : (
                  t("login_title")
                )}
              </ButtonBlock>
            </form>
            <p style={{ textAlign: "center", padding: "5px" }}>
              {t("not_registered_yet")}{" "}
              <Link to="/registration">{t("registration_title")}</Link>
            </p>
          </div>
        </LoginPart>
      </Wrapper>
    </>
  );
}
const UpperPart = styled.div`
  .img-container {
    width: 100%;
    height: 320px;
    padding: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
`;

const LoginPart = styled.div`
  line-height: 25px;
  background-color: #ffffff;
  max-width: 424px;
  padding: 0 15px;
  /* z-index: 9999; */
  /* position: absolute;
  left: 0;
  right: 0; */
  /* bottom: -3%; */
  border-radius: 10px 10px 0 0;

  h2 {
    font-size: 25px;
  }
  p {
    color: #868889;
  }
`;
const Wrapper = styled.div``;

export default LoginPage;
