import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuthContext } from "../../context/AuthContext";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Topbar from "../../components/Topbar";
import shera from "../../assets/images/shera.png";


export default function Welcome() {
  const { t } = useTranslation();
  const { customerLoginInfo } = useAuthContext();
  const axiosPrivate = useAxiosPrivate();
  const [ setCustomerBasicInfo] = useState({});
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCustomerBasicInfo = async () => {
      try {
        const access_token = customerLoginInfo?.token?.access_token;

        const { data } = await axiosPrivate(
          `/user/customer-basic-info`,
          {
            signal: controller.signal,
          },
          { headers: { Authorization: `Bearer ${access_token}` } }
        );
        isMounted && setCustomerBasicInfo(data?.data);
      } catch (error) {

      }
    };
    getCustomerBasicInfo();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const user_name = customerLoginInfo?.customer?.name || "Guest";

  return (
    <Wrapper>
      <Topbar></Topbar>
      <div className="img-container1">
        <img src={shera} alt="" width='100' height='55' />
        <p style={{ textAlign: "center", color: "#DB4D45", padding: "5px" }}>
          একটি এগ্রোশিফট প্রোডাক্ট
        </p>
      </div>
      <h2>{t("welcome_to_agroshift", { user_name })}</h2>
      <p>{t("welcome_to_agroshift_subtitle")}</p>

      <div className="products-table">
      </div>

      <Link to='/products'>Products</Link>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .img-container1 {
    img {
      width: 100px;
      height: 55px;
      object-fit: center;
    }
    p {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      font-weight: 550;
      font-size: 16px;
    }
  }
  text-align: center;
  line-height: 25px;
  overflow-y: auto;

  .icon svg {
    color: #46bcaa;
    width: 130px;
    height: 130px;
  }
  h2 {
    color: #240f51;
    padding: 15px 0;
    span {
      font-weight: 800;
    }
  }
  p {
    font-size: 14px;
    font-weight: 500;
  }


`;
