import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";

function DropdownInput(props) {
  const {
    name,
    placeholder,
    onChange,
    newClass,
    value,
    disabled,
    label,
   options
  } = props;
  
  const {t} = useTranslation();
  return (
    <InputWrapper>
      <select
        name={name}
        value={value}
        className={newClass}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      >
        <option value="" disabled>{t('select_one')}</option>
       {name === 'gender' ?  options.map(option=><option key={option.slug} value={option.slug}>{option.text}</option>) :
        options.map(option=><option key={option.id} value={option.id}>{option.company_name}</option>)
       }
      </select>

      {label && <label>{label}</label>}
    </InputWrapper>
  );
}
const InputWrapper = styled.div`
  position: relative;

  label {
    font-size: 10px;
    color: #8f94a3;
    position: absolute;
    left: 13%;
    top: 22%;
    z-index: 999;
  }

  select {
    width: 135px;
    box-sizing: border-box;
    font-size: 14px;
    height: 61px;
    border-radius: 8px;
    margin: 10px auto;
    border: 0;
    outline: none;
    cursor: pointer;
    padding-left: 14px;
    padding-top: 20px;
    background: #F5F4F5;
    &:focus {
      border: 0;
    }
    option {
      cursor: pointer;
      padding: 24px 0;
    }
  }
  /* @media screen and (max-width: 375px) {
    select{
      width: 135px;
    }
  } */
`;



export default DropdownInput;
