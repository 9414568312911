
import { useAuthContext } from "../context/AuthContext";
import { baseAxios } from "../configs/configs";
import toast from "react-hot-toast";

const useRefreshToken = () => {
  const { customerLoginInfo, setCustomerLoginInfo, bpLoginInfo, setBpLoginInfo } = useAuthContext();
  const refresh = async () => {
    const { data } = await baseAxios.post(
      "/auth/get-token",
      {},
      {
        headers: {
          Authorization: `Bearer ${customerLoginInfo?.token ? customerLoginInfo?.token?.refresh_token : bpLoginInfo?.token?.refresh_token}`,
        },
      },

    );

    if (data?.data?.token) {
      setCustomerLoginInfo((prev) => {
        return { ...prev, token: data?.data?.token };
      });
      setBpLoginInfo((prev) => {
        return { ...prev, token: data?.data?.token };
      });
      const loginInfo = customerLoginInfo?.token ? JSON.parse(sessionStorage.getItem('customerLoginInfo')) : JSON.parse(sessionStorage.getItem('bpLoginInfo'))
      if (customerLoginInfo?.token) {
        sessionStorage.setItem(
          "customerLoginInfo",
          JSON.stringify({ ...loginInfo, token: data?.data?.token })
        );
      }
      else {
        sessionStorage.setItem(
          "bpLoginInfo",
          JSON.stringify({ ...loginInfo, token: data?.data?.token })
        );

      }
      return data?.data?.token?.access_token;
    }
    else {
      setCustomerLoginInfo({});
      setBpLoginInfo({});
      sessionStorage.clear();
      localStorage.clear();
      toast.dismiss();
      toast.error('Token Expired, Please Login again');
    }
  };
  return refresh;
};

export default useRefreshToken;
