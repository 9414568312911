import React, { useEffect, useState } from "react";
import MediaObject from "../../components/MediaObject";
import UserPhotos from "../../assets/images/user-photo.png";
import Cart from "../../assets/images/Buy.svg";
import styled from "styled-components";
import { useCartContext } from "../../context/CartContext";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/crown2.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import HomeIcon from "@mui/icons-material/Home";
import ListAltIcon from "@mui/icons-material/ListAlt";
import {
  BottomNavigation,
  BottomNavigationAction,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useAuthContext } from "../../context/AuthContext";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import BPProducts from "../../components/Products/BPProducts";
function BPDashbaord() {
  const { cartItems } = useCartContext();
  const { bpLoginInfo } = useAuthContext();
  const [products, setProducts] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const getAllProducts = async () => {
      try {
        const { data } = await axiosPrivate(`/products/get-all`, {
          headers: {
            Authorization: `Bearer ${bpLoginInfo?.token?.access_token}`,
          },
        });
        setProducts(data?.data);
      } catch (error) {}
    };

    getAllProducts();
  }, [bpLoginInfo]);


  return (
    <Wrapper>
      <Header>
        <MediaObject
          UserPhoto={UserPhotos}
          UserName={bpLoginInfo?.customer?.name}
          companyName={bpLoginInfo?.customer?.company_details
            ?.map((company) => company.company_name).slice(0,1)
            ?.join(" | ")}
          Location=""
          companies={bpLoginInfo?.customer?.company_details}
          renderFrom="bp"
        />
        <CartContainer>
          <Link to="/cart/bp">
            <img src={Cart} alt="Cart" />
            <p className="cart-amount">{cartItems?.length}</p>
          </Link>
        </CartContainer>
      </Header>

      <Card
        sx={{
          bgcolor: "#651fff",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "400px",
          width: "100%",
          height: "80px",
          mt: "10px",
          textAlign:"center"
        }}
      >
        <CardHeader
          sx={{ color: "white" }}
          avatar={
            <img
              src={logo}
              alt="Shera Crown"
              width={60}
              height={60}
              style={{ color: "white" }}
            />
          }
          titleTypographyProps={{ variant: "h6" }}
          title={`${bpLoginInfo?.customer?.name}`}
          subheaderTypographyProps={{ color: "white" }}
          subheader="Shera Assisted Order"
        />
      </Card>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Link to="/customer/search">
            <Card
              sx={{
                height: "100px",
                bgcolor: "#ff9100",
                margin: "10px 0 10px 0",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <AccountCircleIcon sx={{ fontSize: 50 }} />
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Place an Order
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        {/* <Grid item xs={6}>
          <Link>
            <Card
              sx={{
                height: "100px",
                bgcolor: "#9c27b0",
                color: "white",
                margin: "10px 0 10px 0",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Diversity3Icon sx={{ fontSize: 50 }} />
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Group Order
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid> */}
      </Grid>
      <BPProducts products={products} />
      {/* <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 999 }}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction label="My Orders" icon={<ListAltIcon />} />
        </BottomNavigation>
      </Paper> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 10px 0;
  position: relative;
  .checkout {
    position: fixed;
    bottom: 4%;
    left: 0;
    right: 0;
  }
  a {
    text-decoration: none;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin: 0 16px;
`;

const CartContainer = styled.section`
  position: relative;

  .cart-amount {
    position: absolute;
    top: -30%;
    right: -40%;
    border: 1px solid;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    background-color: #ee3d3c;
    color: white;
  }
`;

export default BPDashbaord;
