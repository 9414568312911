import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { apiSecret, baseAxios, baseURL } from "../../configs/configs";
import ButtonBlock from "../../components/Buttons/ButtonBlock";
import { toast } from "react-hot-toast";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
} from "@mui/material";
import TelInput from "../../components/CustomInputs/TelInput";
import OtpBox from "../../components/OtpBox";
import Topbar from "../../components/Topbar";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";
import { banglaLoginErrorMessage } from "../../helpers/convert";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Carousel from "react-material-ui-carousel";
import Searchbar from "../../components/Searchbar";
import axios from "axios";

function BPSearchPage() {
  const navigate = useNavigate();
  const { lang, carousel } = useAppContext();
  const { bpLoginInfo } = useAuthContext();
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [searchedCustomers, setSearchedCustomers] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const carousels = carousel?.filter(
    (s) => s?.app_location?.toLowerCase() === "login_page"
  );

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${baseURL}/bp/search-user`,
        {
          search_string: query,
          company_ids: bpLoginInfo?.customer?.company_details?.map((c) => c.id),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bpLoginInfo?.token.access_token}`,
          },
        }
      );
      setSearchedCustomers(data?.data);
      localStorage.setItem("selected_customer", JSON.stringify(data?.data));
      setLoading(false);
      setMessage(data?.message);
    } catch (error) {
      setSearchedCustomers(null);
      setLoading(false);
      setMessage(error?.response.data.message);
    }
  };

  return (
    <>
      <Wrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            marginLeft: "20px",
          }}
        >
          <div
            className="backbutton"
            onClick={() => navigate("/dashboard/bp")}
            style={{ marginRight: "10px", cursor: "pointer" }}
          >
            <KeyboardBackspaceIcon />
          </div>
          <h3>Search</h3>
        </div>
        <UpperPart>
          <Carousel
            animation="slide"
            indicators={carousels?.length < 1 && false}
            navButtonsAlwaysVisible={false}
            navButtonsProps={{
              style: {
                display: "none",
                opacity: 0,
              },
            }}
          >
            {carousels?.map((c, idx) => (
              <div className="img-container" key={idx}>
                <img src={c["image_url"]} alt="" height="100%" width="100%" />
              </div>
            ))}
          </Carousel>
        </UpperPart>
        <LoginPart>
          <h4>{t("bp_search_title")}</h4>
          {/* <p>{t("login_subtitle_bp")}</p> */}
          <form onSubmit={handleSearch}>
            <Searchbar query={query} setQuery={setQuery} />
          </form>

          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
              <h5>Searching user...</h5>
            </div>
          ) : searchedCustomers ? (
            <div>
              <p> Search Result</p>
              <Link to="/products/bp">
                <Card sx={{ margin: "10px 0" }}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                        {searchedCustomers?.name.slice(0, 1)}
                      </Avatar>
                    }
                    title={`${searchedCustomers?.name}`}
                    titleTypographyProps={{ variant: "h6", color: "#651fff" }}
                    subheader={
                      <>
                        <p>
                          ID - {searchedCustomers?.emp_id} |{" "}
                          {searchedCustomers?.company.company_name}
                        </p>
                        <p>
                          Shera ID -{" "}
                          <strong>{searchedCustomers?.username}</strong>
                        </p>
                      </>
                    }
                  />
                </Card>
              </Link>
            </div>
          ) : (
            <p
              style={{ textAlign: "center", padding: "5px", marginTop: "20px" }}
            >
              {message}
            </p>
          )}
        </LoginPart>
      </Wrapper>
    </>
  );
}
const UpperPart = styled.div`
  .img-container {
    width: 100%;
    height: 320px;
    padding: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
`;

const LoginPart = styled.div`
  line-height: 50px;
  background-color: #ffffff;
  max-width: 424px;
  padding: 0 15px;
  border-radius: 10px 10px 0 0;

  h2 {
    font-size: 25px;
  }
  p {
    color: #868889;
  }
`;
const Wrapper = styled.div`
  a {
    text-decoration: none;
  }
`;

export default BPSearchPage;
