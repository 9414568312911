import React from "react";
import styled from "styled-components";
import ProductPhoto from "../assets/images/product.png";
import plus from "../assets/images/Group 116.png";
import minus from "../assets/images/Group 117.svg";
import { useCartContext } from "../context/CartContext";
import { convertToBanglaNumber } from "../helpers/convert";
import { useAppContext } from "../context/AppContext";

function ProductCard({
  id,
  image_url,
  name,
  price,
  unit,
  unit_type,
  unit_amount,
  mrp,
  market_price,
  packaging,
}) {
  const { handleAddToCart, cartItems } = useCartContext();
  const { lang } = useAppContext();
  const cartItem = cartItems.find((item) => item.id === id);

  return (
    <Wrapper>
      <div className="item">
        <img src={image_url || ProductPhoto} width="60" height="60" alt="" />
        <ContentDiv>
          <div className="product_name">{name}</div>
          <Price>
            <span>
              {lang?.checkedA
                ? "৳" + convertToBanglaNumber(market_price.toFixed(2))
                : market_price.toFixed(2) + " Tk"}
            </span>
            <br />
            {lang?.checkedA
              ? "৳" + convertToBanglaNumber(mrp.toFixed(2))
              : mrp.toFixed(2) + " Tk"}
          </Price>
          <div className="unit">
            {unit_amount} {unit} {packaging && "/ " + packaging}
          </div>
        </ContentDiv>
      </div>
      {/* <hr /> */}
      <div className="addToCart">
        <div
          onClick={() =>
            handleAddToCart(
              { id, name, price, image_url, unit, unit_type, mrp,unit_amount },
              "inc"
            )
          }
        >
          <img src={plus} alt="+" width="24" height="24" />
        </div>
        <div className="amount">
          {cartItem?.amount > 0 ? cartItem?.amount : 0}
        </div>
        {cartItem?.amount > 0 && (
          <div
            onClick={() =>
              handleAddToCart(
                { id, name, price, image_url, unit, unit_type,unit_amount },
                "dec"
              )
            }
          >
            <img src={minus} alt="-" width="24" height="24" />
          </div>
        )}
      </div>
    </Wrapper>
  );
}
const ContentDiv = styled.div`
  padding: 5px 2px;
  .product_name {
    margin: 5px;
    font-size: 13px;
    font-weight: 600;
    color: #25435b;
  }
  .unit {
    margin: 0;
    font-size: 11px;
    color: #868889;
  }
`;
const Wrapper = styled.div`
  text-align: center;
  background: #fff;
  width: calc(50% - 8px);
  min-height: 220px;
  padding: 5px;
  border-radius: 16px;
  box-sizing: border-box;
  margin: 5px 0;
  border: 1px solid #e6e6e6;
  position: relative;

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 55px;
    height: 55px;
    margin: 0 auto;
    object-fit: cover;
  }
  /* hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #eaeaea;
    margin: 5px 0;
    padding: 0;
  } */
  .addToCart {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    overflow-x: hidden;
    border-top: 1px solid #eaeaea;
    padding: 5px;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .amount {
      background-color: #eeeeee;
      padding: 1px 20px;
      border-radius: 16px;
      color: #171c46;
      margin: 10px;
      font-size: 24px;
      font-weight: 500;
      margin-top: 0;
    }
  }
`;
const Price = styled.p`
  margin: 2px;
  font-size: 16px;
  color: #03a89e;
  font-weight: 600;
  span {
    position: relative;
    font-size: 13px;
    color: #25435b;
    white-space: nowrap;
  }
  span::before {
    position: absolute;
    content: "";
    left: 0;
    top: 45%;
    right: 0;
    border-top: 2px solid;
    border-color: inherit;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
`;

export default ProductCard;
