import React, { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useAuthContext } from "./AuthContext";

const OrderContext = React.createContext();

export const OrderProvider = ({ children }) => {
    const { customerLoginInfo, bpLoginInfo } = useAuthContext();
    const access_token = customerLoginInfo?.token ? customerLoginInfo?.token?.access_token : bpLoginInfo?.token?.access_token
    const axiosPrivate = useAxiosPrivate();

    const [latestOrders, setLatestOrders] = useState([]);

    const getLatestOrders = async () => {
        try {
            const { data } = await axiosPrivate(
                `/orders/get-by-customer`,
                
                { headers: { Authorization: `Bearer ${access_token}` } }
            );
            setLatestOrders(data?.data);
        } catch (error) {

        }
    };

    return (
        <OrderContext.Provider
            value={{
               getLatestOrders,
               latestOrders

            }}
        >
            {children}
        </OrderContext.Provider>
    );
};

export const useOrderContext = () => {
    return useContext(OrderContext);
};
