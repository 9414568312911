import React from "react";
import styled from "styled-components";
import group from "../assets/images/group.svg";
function Container({ children }) {
  return (
    <Page>
      <div className="content">{children}</div>
      <div className="group-image">
        <img src={group} alt="" height='24' width='100%' />
        <img src={group} alt="" height='24' width='100%' />
      </div>
    </Page>
  );
}

const Page = styled.section`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 24px);
  width: 100vw;
  margin: 0 auto;
  position: relative;
  max-width: 400px;
  background-color: #ffffff;
  padding: 5px;
  /* border: 1px solid; */
  .content {
    flex: 1;
    padding: 5px;
  }
  .group-image {
    display: flex;
    justify-content: space-around;
    opacity: 0.8;
    bottom: 0;
    img {
      height: 24px;
      width: 100%;
    }
  }
`;

export default Container;
