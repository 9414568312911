import React, { useEffect, useState } from "react";
import MediaObject from "../../components/MediaObject";
import UserPhotos from "../../assets/images/user-photo.png";
import Cart from "../../assets/images/Buy.svg";
import styled from "styled-components";
import { useAuthContext } from "../../context/AuthContext";
import ButtonBlock from "../../components/Buttons/ButtonBlock";
import { useCartContext } from "../../context/CartContext";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProductCardForHistory from "../../components/ProductCardForHistory";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useOrderContext } from "../../context/OrderContext";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function OrderHistory() {
  const { customerLoginInfo } = useAuthContext();
  const { cartItems } = useCartContext();
  const { getLatestOrders, latestOrders } = useOrderContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  useEffect(() => {
    getLatestOrders();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setCurrentPage(1); // Reset to the first page when tab changes
  };

  const filteredOrders = latestOrders?.filter((order) =>
    selectedTab === 0 ? order.is_paid === false : order.is_paid === true
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOrders = filteredOrders?.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredOrders.length / itemsPerPage)) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <Wrapper>
      <Header>
        <MediaObject
          UserPhoto={UserPhotos}
          UserName={customerLoginInfo?.customer?.name}
          companyName={customerLoginInfo?.customer?.company_name}
          Location=""
        />
        <CartContainer>
          <Link to="/cart">
            <img src={Cart} alt="Cart" />
            <p className="cart-amount">{cartItems?.length}</p>
          </Link>
        </CartContainer>
      </Header>

      <div className="order_history_title">
        <div className="order_history">
          <ListAltIcon />
          <h3>{t("latest_orders")}</h3>
        </div>
        <div className="tabs-container">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            className="tabs"
            TabIndicatorProps={{ className: "tab-indicator" }}
          >
            <Tab label="Pending" className="tab" />
            <Tab label="Delivered" className="tab" />
          </Tabs>
        </div>
      </div>

      <>
        {filteredOrders?.length < 1 ? (
          <div className="no_order">
            <SentimentVeryDissatisfiedIcon
              sx={{ fontSize: "35px", color: "#db4d45" }}
            />
            <h2>Nothing Found</h2>
          </div>
        ) : (
          <>
            {currentOrders?.map((item) => {
              return <ProductCardForHistory key={item.id} {...item} />;
            })}
            <PaginationControls>
              <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                <ChevronLeftIcon />
              </button>
              <span>
                {t("page")} {currentPage} {t("of")}{" "}
                {Math.ceil(filteredOrders.length / itemsPerPage)}
              </span>
              <button
                onClick={handleNextPage}
                disabled={
                  currentPage === Math.ceil(filteredOrders.length / itemsPerPage)
                }
              >
                <ChevronRightIcon />
              </button>
            </PaginationControls>
          </>
        )}
      </>
      <form action="" onSubmit={() => navigate("/products")}>
        <div className="checkout">
          <ButtonBlock startIcon={<ArrowBackIcon/>}>{t("back")}</ButtonBlock>
        </div>
      </form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 10px 0;
  position: relative;
  .checkout {
    position: fixed;
    bottom: 4%;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .order_history_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .order_history {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
    margin: 16px 0 12px 0;
  }
  .tabs-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tabs {
    display: flex;
    gap: 5px; /* Adjust the gap between tabs */
  }

  .tab {
    font-size: 12px;
    min-width: 50px;
  }
  .tab-indicator {
    background-color: #ee3d3c;
  }
  .tab.Mui-selected {
    color: #ee3d3c;
  }
  .no_order {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin: 0 16px;
`;

const CartContainer = styled.section`
  position: relative;

  .cart-amount {
    position: absolute;
    top: -30%;
    right: -40%;
    border: 1px solid;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    background-color: #ee3d3c;
    color: white;
  }
`;

const PaginationControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;

  button {
    background-color: #ee3d3c;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }

  span {
    font-size: 14px;
    margin-top: -15px;
  }
`;

export default OrderHistory;
