import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import ListIcon from "@mui/icons-material/List";
import SearchIcon from "@mui/icons-material/Search";

export default function Searchbar({ query, setQuery }) {
  return (
    <Paper
    
      sx={{
        p: "2px",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <InputBase
        type="search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        sx={{ ml: 1, flex: 1,fontSize:"12px" }}
        placeholder="Search by ID, Mobile No or Worker ID"
        inputProps={{ "aria-label": "search keywords" }}
      />
      <IconButton type="submit" sx={{ p: "8px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
