import React, { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useAuthContext } from "./AuthContext";

const CartContext = React.createContext();

export const CartProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [cartAmount, setCartAmount] = useState(0);
    const [cartTotal, setCartTotal] = useState(0);
    const [discountedAmount, setDiscountedAmount] = useState(0);
    const { customerLoginInfo, bpLoginInfo } = useAuthContext();

    const access_token = customerLoginInfo?.token ? customerLoginInfo?.token?.access_token : bpLoginInfo?.token?.access_token
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {

        const getAllProducts = async () => {
            try {
                const { data } = await axiosPrivate(
                    `/products/get-all`,
                    {
                        signal: controller.signal,
                    },
                    { headers: { Authorization: `Bearer ${access_token}` } }
                );
                isMounted && setProducts(data?.data);
            } catch (error) {

            }
        };
        let isMounted = true;
        const controller = new AbortController();
        access_token && getAllProducts();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [access_token]);

    async function saveToRedis(cart) {

        try {
            const { data } = await axiosPrivate.post(
                "/cart/create",
                cart,
                { headers: { Authorization: `Bearer ${access_token}` } }

            );

        } catch (error) {
            console.log(error)
        }
    }

    const handleAddToCart = (item, action) => {

        const itemInCart = cartItems?.find(cartItem => cartItem.id === item.id);
        let tempCart;

        if (itemInCart) {
            tempCart = cartItems.map(cartItem => {
                if (cartItem.id === item.id && action === 'inc') {
                    return { ...cartItem, amount: cartItem.amount + 1 }
                }
                if (cartItem?.amount > 0 && cartItem.id === item.id && action === 'dec') {
                    return { ...cartItem, amount: cartItem.amount - 1 }
                }
                return cartItem
            }).filter(item => item.amount !== 0)
        }
        else {
            tempCart = [...cartItems, { ...item, amount: 1 }]
        }

        setCartItems(tempCart);
        saveToRedis(tempCart)

    }

    const getCart = async () => {
        let isMounted = true;
        const controller = new AbortController();
        try {
            const { data } = await axiosPrivate(`/cart/get-cart`,
                {
                    signal: controller.signal,
                }, { headers: { Authorization: `Bearer ${access_token}` } }
            );
            isMounted && setCartItems(data?.data)
            return () => {
                isMounted = false;
                controller.abort();
            };
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        access_token && getCart();
    }, [access_token])

    const removeFromCart = (id) => {
        setCartItems(cartItems?.filter(item => item.id !== id))
        saveToRedis(cartItems?.filter(item => item.id !== id))
    }

    const increase = (id) => {
        let tempCart = cartItems.map(item => {
            if (item.id === id) {
                return { ...item, amount: item.amount + 1 }
            }
            return item
        })

        setCartItems(tempCart)
        saveToRedis(tempCart)
    }
    const decrease = (id) => {
        let tempCart = cartItems.map(item => {
            if (item.id === id) {
                return { ...item, amount: item.amount - 1 }
            }
            return item
        }).filter(item => item.amount !== 0)
        setCartItems(tempCart)
        saveToRedis(tempCart)
    }

    const getToatal = () => {
        let { total, amount } = cartItems?.length > 0 && cartItems?.reduce(
            (cartTotal, cartItem) => {
                const { amount, mrp } = cartItem
                const itemTotal = mrp * amount

                cartTotal.total += itemTotal
                cartTotal.amount += amount
                return cartTotal
            },
            {
                total: 0,
                amount: 0,
            }
        )
        total = parseFloat(total?.toFixed(2))

        setCartAmount(amount || 0)
        setCartTotal(total || 0)


    }

    useEffect(() => {
        getToatal();
    }, [cartItems])

    const checkFirstOrder = async () => {
        try {
            const { data } = await axiosPrivate(
                `/user/check-first-order-eligibility`,
                {
                    headers: { Authorization: `Bearer ${access_token}` },
                }
            );

            if (data?.status_code === 200) {
                if (data?.data.discount_type == "percentage") {
                    cartTotal &&
                        setDiscountedAmount(
                            cartTotal * (data?.data?.discount_value / 100.0)
                        );
                } else if (data?.data.discount_type == "fixed") {
                    setDiscountedAmount(data?.data?.discount_value);
                }
            }
            else {
                setDiscountedAmount(0)
            }
        } catch (error) {
            console.log(`error in fetching first-order-eligibility ${error}`);
        }
    };


    return (
        <CartContext.Provider
            value={{
                products,
                cartAmount,
                handleAddToCart,
                removeFromCart,
                increase,
                decrease,
                cartItems,
                setCartItems,
                cartTotal,
                saveToRedis,
                checkFirstOrder,
                discountedAmount,
                getCart

            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export const useCartContext = () => {
    return useContext(CartContext);
};
