import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StoreFront from "../assets/images/s1.PNG";
import ButtonBlock from "../components/Buttons/ButtonBlock";
import OtpBox from "../components/OtpBox";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { apiSecret, baseAxios } from "../configs/configs";
import { toast } from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import Topbar from "../components/Topbar";
import TelInput from "../components/CustomInputs/TelInput";
import { useTranslation } from "react-i18next";

function VerifyOTP() {
  const [otp, setOtp] = useState("");
  const { number, otpTimeLimit, setOtpTimeLimit, sendOTP } = useAuthContext();
  const [minutes, setMinutes] = useState(otpTimeLimit / 60);
  const [seconds, setSeconds] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const resendOTP = async () => {
    try {
      setLoading(true);
      const { data } = await apiSecret.post("/auth/send-otp", {
        msisdn: number,
      });
      setLoading(false);
      if (data.status === "SUCCESS") {
        localStorage.setItem("otp_token", JSON.stringify(data?.data.otp_token));
        setOtpTimeLimit(Number(data?.data.validation_time));
        navigate("/verify-otp");
        toast.dismiss();
        toast.success(data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.dismiss();
      toast.error(error?.response?.data?.message);
    }

    setMinutes(otpTimeLimit / 60);
    setSeconds(0);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (otp.length == 4) {
        const { data } = await baseAxios.post(
          "/auth/verify-otp",
          {
            msisdn: number,
            otp,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("otp_token")
              )}`,
            },
          }
        );

        setLoading(false);
        if (data.status === "SUCCESS") {
          localStorage.setItem(
            "one_time_token",
            JSON.stringify(data?.data.one_time_token)
          );
          localStorage.setItem("msisdn", JSON.stringify(data?.data.msisdn));
          navigate("/registration-complete");
          toast.success(data.message);
        }
      } else {
        setLoading(false);
        toast.error("Please insert Correct OTP");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes, sendOTP, number, resendOTP]);

  // async function fetchOtp() {
  //   if ("OTPCredential" in window) {
  //     console.log(navigator);
  //     try {
  //       const content = await navigator?.credentials.get({
  //         otp: { transport: ["sms"] },
  //       });
  //       content && setOtp(content.code);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   }
  // }

  useEffect(() => {
    let ac = new AbortController();
    setTimeout(() => {
      // abort after 5 minutes
      ac.abort();
    }, 5 * 60 * 1000);
    navigator.credentials
      .get({
        otp: { transport: ["sms"] },
        signal: ac.signal,
      })
      .then((otp) => {
        setOtp(otp.code);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  return (
    <Wrapper>
      <Topbar>{t("welcome_header")}</Topbar>
      <UpperPart>
        <div className="img-container">
          <img src={StoreFront} alt="" width="100%" height="100%" />
        </div>
      </UpperPart>
      <OtpPart>
        <h2>{t("registration_title")}</h2>
        <p>{t("verify_otp_subtitle")}</p>
        <form onSubmit={submitHandler}>
          <TelInput
            value={number}
            // value={localStorage.getItem("msisdn")}
            isDisabled={true}
          />
          <OtpBox
            otpClass="otp-area"
            otp={otp}
            setOtp={setOtp}
            length={4}
            name="verify_otp"
          />
          <div className="countdown-text">
            {seconds > 0 || minutes > 0 ? (
              <p>
                {t("time_left")}: {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </p>
            ) : (
              <p>{t("didnot_recieve_code")}</p>
            )}

            <button
              className="resend"
              disabled={seconds > 0 || minutes > 0}
              style={{
                color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                border:
                  seconds > 0 || minutes > 0
                    ? "1px solid #DFE3E8"
                    : "1px solid #FF5630",
              }}
              onClick={resendOTP}
            >
              {t("resend_otp")}
            </button>
          </div>
          {/* <ButtonBlock onClick={() => submitHandler()}>SUBMIT</ButtonBlock> */}
          <ButtonBlock type="submit" disabled={loading && true}>
            {loading ? (
              <CircularProgress color="primary" />
            ) : (
              t("verify_otp_button")
            )}
          </ButtonBlock>
        </form>
      </OtpPart>
    </Wrapper>
  );
}

const Wrapper = styled.div``;
const UpperPart = styled.div`
  .img-container {
    width: 100%;
    height: 320px;
    padding: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
`;
const OtpPart = styled.div`
  line-height: 25px;
  max-width: 424px;
  padding: 15px;
  border-radius: 10px 10px 0 0;
  h2 {
    font-size: 25px;
  }
  p {
    color: #868889;
  }
  .countdown-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    .resend {
      cursor: pointer;
    }
  }
`;
export default VerifyOTP;
