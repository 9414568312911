import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useAuthContext } from "../../context/AuthContext";
import { convertToBanglaNumber } from "../../helpers/convert";
import { useAppContext } from "../../context/AppContext";
import { Skeleton } from "@mui/material";

export default function BPProducts({ products }) {
  const { t } = useTranslation();
  const { lang } = useAppContext();
  return (
    <Wrapper>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          bgcolor: "#F5F4F5",
          mt: 5,
          boxShadow: "none",
          textAlign: "center",
        }}
      >
        <h2>{t("our_products")}</h2>
        <p>{t("prices_comparision_with_market")}</p>
        <div className="border">
          <hr />
          <hr />
        </div>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  {t("product_name")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  {t("market_price")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  {t("our_price")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ textDecoration: "line-through" }}
                  >
                    {convertToBanglaNumber(row.market_price)}&#2547;
                  </TableCell>
                  <TableCell align="center">
                    {convertToBanglaNumber(row.mrp)}&#2547;
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin: 15px 0;

  hr {
    border: 0.5px solid #db4d45;
    margin: 2px;
  }

  .border {
    padding: 10px;
  }

  @media only screen and (max-width: 320px) {
  }
`;
