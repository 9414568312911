import i18next from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { baseURL } from "../configs/configs";
import axios from "axios";

const AppContext = React.createContext();

export const AppProvider = ({ children }) => {

    const [lang, setLang] = useState({
        checkedA: true,
        lang: document.cookie.split('=')[1].toUpperCase(),
    })

    const [carousel, setCarousel] = useState([]);
    const [carouselLoading,setCarouselLoading] = useState(false);

    const handleLanguageChange = (event) => {

        if (lang?.checkedA) {
            setLang({ ...lang, lang: "EN", [event.target.name]: event.target.checked });
            i18next.changeLanguage('en')
        } else {
            setLang({ ...lang, lang: "BN", [event.target.name]: event.target.checked });
            i18next.changeLanguage('bn')
        }
    }


    async function getCarousels() {
  
        try {
            setCarouselLoading(true);
            const { data } = await axios.get(`${baseURL}/misc/get-all-banners`);
            setCarousel(data?.data)
            setCarouselLoading(false);
        } catch (error) {
            setCarouselLoading(false);
            console.log(`Error in fetching carusel ${error}`)
        }
    }
    useEffect(() => {
        getCarousels()
    }, [])


    return (
        <AppContext.Provider
            value={{
                handleLanguageChange,
                lang,
                carousel,
                carouselLoading
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    return useContext(AppContext);
};
