import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import CustomSwitch from "./Buttons/Switch";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useAuthContext } from "../context/AuthContext";
import LogoutIcon from "@mui/icons-material/Logout";
import sheraCrown from '../assets/images/Shera Crown Icon.svg';

export default function Topbar({ children }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuthContext();
  
  const logoutHandler = async () => {
    await logout();
    navigate('/login')
  }


  return (
    <Wrapper>
      <div className="topbar">
     
        {pathname === "/" ? (
         <img src={sheraCrown} alt="" height='50' width='50' />
        ) 
        : pathname === "/welcome" ? (
       
          <div onClick={logoutHandler} style={{cursor:"pointer"}}><LogoutIcon/></div>

        ) 
        
        :  (
          <div className="backbutton" onClick={() => navigate(-1)}>
            <KeyboardBackspaceIcon />
          </div>
        )}
      {pathname == '/products/bp'? children : <CustomSwitch  />}
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  .topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #131313;
    font-weight: 500;
    padding: 0 15px;
    .backbutton {
      background-color: #fff;
      border-radius: 100%;
      padding: 3px;
      margin: 5px;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    .skip{
      color: #DB4D45;
      background-color: #F5F4F5;
      width: 68px;
      height: 26px;
      border-radius: 33px;
      display: flex;
      justify-content:center;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
    }

  }
`;
