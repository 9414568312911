import React from "react";
import MediaObject from "../../components/MediaObject";
import UserPhotos from "../../assets/images/user-photo.png";
import Cart from "../../assets/images/Buy.svg";
import styled from "styled-components";
import ProductCard from "../../components/ProductCard";
import { useAuthContext } from "../../context/AuthContext";
import ButtonBlock from "../../components/Buttons/ButtonBlock";
import { useCartContext } from "../../context/CartContext";
import { Link, useNavigate } from "react-router-dom";
import OfferSlider from "../../components/Products/Slider/OfferSlider";
import { useTranslation } from "react-i18next";
import LocalMallIcon from '@mui/icons-material/LocalMall';

function Products() {
  const { customerLoginInfo } = useAuthContext();
  const { products, cartItems } = useCartContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCheckout = () => {
    navigate("/cart");
  };

  return (
    <Wrapper>
      <Header>
        <MediaObject
          UserPhoto={UserPhotos}
          UserName={customerLoginInfo?.customer?.name}
          companyName={customerLoginInfo?.customer?.company_name}
          Location=""
        />
        <CartContainer>
          <Link to="/cart">
            <img src={Cart} alt="Cart" />
            <p className="cart-amount">{cartItems?.length}</p>
          </Link>
        </CartContainer>
      </Header>

      <OfferSlider />
      <div className="order_history_title">
        <LocalMallIcon className="bagicon" />
        <Link to='/order-history'>{t('view_latest_order')}</Link>
      </div>
     

      <FeatureProduct>
        {products.map((item) => {
          return <ProductCard key={item.id} {...item} />;
        })}
        
      </FeatureProduct>
      <form action="" onSubmit={handleCheckout}>
        <div className="checkout">
          <ButtonBlock isDisabled={cartItems?.length < 1 && true}>
            {t("checkout_button_text")}
          </ButtonBlock>
        </div>
      </form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 5px 0 20px 0;
  position: relative;
  .checkout{
    position: fixed;
    bottom:0;
    left: 0;
    right: 0;
    z-index: 999;

  }
  .order_history_title{
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    font-size: 18px;
    margin: 8px 0 12px 0;
    .bagicon{
      color: #03A89E;
      font-size: 40px;
    }
    a{
      color: #03A89E;
      text-decoration: none;
      border-bottom:1px solid #03A89E;
    }
  }
`;

const FeatureProduct = styled.div`
  max-width: 424px;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  h2 {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin: 0 16px;
`;

const CartContainer = styled.section`
  position: relative;

  .cart-amount {
    position: absolute;
    top: -30%;
    right: -40%;
    border: 1px solid;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    background-color: #ee3d3c;
    color: white;
  }
`;

export default Products;
