
import React, { useEffect } from 'react'
import useRefreshToken from './useRefreshToken'
import { useAuthContext } from '../context/AuthContext';
import { baseAxios } from '../configs/configs';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';


export default function useAxiosPrivate() {
    const refresh = useRefreshToken();
    const {customerLoginInfo,setCustomerLoginInfo,bpLoginInfo,setBpLoginInfo} = useAuthContext();
    const {t} = useTranslation();
    useEffect(()=>{
       
        const requestIntercept = baseAxios.interceptors.request.use(
            config => {
            
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${customerLoginInfo?.token ? customerLoginInfo?.token?.access_token : bpLoginInfo?.token?.access_token}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );  

        const responseIntercept = baseAxios.interceptors.response.use(
            response => response,
            async (error) => {
             
                const prevRequest = error?.config;
                if ((error?.response?.data.status_code === 498) && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return baseAxios(prevRequest);
                }
                if(error?.response?.data.status_code === 401){
                    setCustomerLoginInfo({});
                    setBpLoginInfo({});
                    sessionStorage.clear();
                    localStorage.clear();
                    toast.dismiss();
                    toast.error(t('token_expired'));
                }
                return Promise.reject(error);
            }
        );

        return () => {
            baseAxios.interceptors.request.eject(requestIntercept);
            baseAxios.interceptors.response.eject(responseIntercept);
        }
    },[customerLoginInfo,bpLoginInfo,refresh])

  return baseAxios;
}
