import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Container from "./containers/Container";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["bn", "en"],
    fallbackLng: "en",
    lng: "bn", //default
    detection: {
      order: [
        "cookie",
        "htmlTag",
        "localStorage",
        "sessionStorage",
        "path",
        "subdomain",
      ],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

function App() {
  setTimeout(function () {
    sessionStorage.removeItem("otp_token");
  }, 600000);

  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
  
  return (
    <Container>
      <Outlet />
      <Toaster
        toastOptions={{
          duration: 4000,
        }}
        position="top-center"
        reverseOrder={false}
      />
    </Container>
  );
}

export default App;
