import React, { useContext, useState } from "react";
import {baseAxios } from "../configs/configs";
import { toast } from "react-hot-toast";
import { useAppContext } from "./AppContext";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {

  const [customerLoginInfo, setCustomerLoginInfo] = useState(
    localStorage.getItem("customerLoginInfo")
      ? JSON.parse(localStorage.getItem("customerLoginInfo"))
      : {}
  );

  const [bpLoginInfo, setBpLoginInfo] = useState(
    localStorage.getItem("bpLoginInfo")
      ? JSON.parse(localStorage.getItem("bpLoginInfo"))
      : {}
  );

  const [number, setNumber] = useState(localStorage.getItem('msisdn') ? JSON.parse(localStorage.getItem('msisdn')) : '');
  const [pin, setPin] = useState("");
  const [otpTimeLimit, setOtpTimeLimit] = useState(localStorage.getItem('validation_time') ? localStorage.getItem('validation_time') : 0);
  const [loginLoading, setLoginLoading] = useState(false);
  const { lang } = useAppContext();

  const logout = async () => {
    try {
      const { data } = await baseAxios.post(`/auth/logout`, {}, {
        headers: { 'Authorization': `Bearer ${customerLoginInfo?.token.access_token}` }
      })

      if (data.status === 'SUCCESS') {
        customerLoginInfo.customer.has_logged_in = false;
        localStorage.clear();
        sessionStorage.clear();
        setCustomerLoginInfo({})
        const logout_success_message = lang.checkedA ? 'লগআউট সফল হয়েছে' : data.message;
        toast.dismiss();
        toast.success(logout_success_message)
      }

    } catch (error) {
      toast.dismiss();
      toast.error(error?.response?.data?.message)
    }
  }

  const bPlogout = async () => {
    try {
      const { data } = await baseAxios.post(`/auth/logout`, {}, {
        headers: { 'Authorization': `Bearer ${bpLoginInfo?.token?.access_token}` }
      })

      if (data.status === 'SUCCESS') {
        setBpLoginInfo({});
        localStorage.clear();
        sessionStorage.clear();
        const logout_success_message = lang.checkedA ? 'লগআউট সফল হয়েছে' : data.message;
        toast.dismiss();
        toast.success(logout_success_message)
      }

    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        number,
        setNumber,
        customerLoginInfo,
        setCustomerLoginInfo,
        otpTimeLimit,
        setOtpTimeLimit,
        loginLoading,
        setPin,
        logout,
        bpLoginInfo,
        setBpLoginInfo,
        bPlogout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
