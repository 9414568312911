import axios from "axios";

export const baseURL = process.env.REACT_APP_BASE_URL;

  // const currentTimestmp = 1680905614;
  // // const currentTimestmp = moment().unix();
  // const secret = process.env.REACT_APP_OWN_HASH_SECRET;
  // const hash = crypto.createHmac('sha256',secret)
  // .update(`${process.env.REACT_APP_OWN_HASH_KEY}${currentTimestmp}`)
  // .digest('hex')


const apiSecret = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "*",
    "Access-Control-Allow-Origin": "*",
    timestamp:'1680905614',
    secret:'b9f75186959e5bac58da1b8fc4e27a7ba93454233630984788275a1cd24ff01e'
  },
});

const baseAxios = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "*",
    "Access-Control-Allow-Origin": "*",
  },
});

export { baseAxios, apiSecret };
