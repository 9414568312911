import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { apiSecret } from "../configs/configs";
import ButtonBlock from "../components/Buttons/ButtonBlock";
import { toast } from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import TelInput from "../components/CustomInputs/TelInput";
import OtpBox from "../components/OtpBox";
import Topbar from "../components/Topbar";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../context/AppContext";
import { banglaLoginErrorMessage } from "../helpers/convert";
import Carousel from "react-material-ui-carousel";

function LoginPageBP() {
  const navigate = useNavigate();
  const {
    customerLoginInfo,
    setCustomerLoginInfo,
    setNumber,
    number,
    bpLoginInfo,
    setBpLoginInfo,
  } = useAuthContext();
  const { lang, carousel } = useAppContext();
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [numberError, setNumberError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const carousels = carousel?.filter(
    (s) => s?.app_location?.toLowerCase() === "login_page"
  );

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const { data } = await apiSecret.post(`/auth/admin/login`, {
        username,
        password,
      });
      setLoading(false);

      if (data?.status_code === 200) {
        localStorage.setItem("bpLoginInfo", JSON.stringify(data?.data));
        setBpLoginInfo(data?.data);
        setNumberError(false);
        setPasswordError(false);
        navigate("/dashboard/bp");
        const login_success_message = lang.checkedA
          ? "লগইন সফল হয়েছে"
          : data.message;
        toast.success(login_success_message);
      }
    } catch (error) {
      setLoading(false);
      let error_message = error?.response?.data?.message;
      error_message?.startsWith("MSISDN") ||
      error_message?.startsWith("Invalid Phone")
        ? setNumberError(true)
        : setPasswordError(true);
      error_message = banglaLoginErrorMessage(error_message, lang);
      if(error_message){
        toast.dismiss();
        toast.error(error_message);
      }
      else{
        toast.dismiss();
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    setPasswordError(false);
  }, [password]);

  useEffect(() => {
    if (bpLoginInfo?.token?.access_token) {
      navigate("/dashboard/bp");
    }
  }, [bpLoginInfo]);

  return (
    <>
      <Wrapper>
        <Topbar>{t("login_title")}</Topbar>
        <UpperPart>
          <Carousel
            animation="slide"
            indicators={carousels?.length < 1 && false}
            navButtonsAlwaysVisible={false}
            navButtonsProps={{
              style: {
                display: "none",
                opacity: 0,
              },
            }}
          >
            {carousels?.map((c, idx) => (
              <div className="img-container" key={idx}>
                <img src={c["image_url"]} alt="" height="100%" width="100%" />
              </div>
            ))}
          </Carousel>
        </UpperPart>
        <LoginPart>
          <h1>{t("login_title")}</h1>
          {/* <p>{t("login_subtitle_bp")}</p> */}
          <form onSubmit={handleLogin}>
            <TelInput
              renderFrom="bp"
              setNumber={setUsername}
              value={username}
              text={t("username")}
              type="text"
            />
            <TelInput
              renderFrom="bp"
              setNumber={setPassword}
              numberError={numberError}
              value={password}
              text={t("password")}
              type="password"
            />

            <ButtonBlock type="submit" isDisabled={(loading || !username || !password) && true}>
              {loading ? (
                <CircularProgress color="primary" />
              ) : (
                t("login_title")
              )}
            </ButtonBlock>
          </form>
        </LoginPart>
      </Wrapper>
    </>
  );
}
const UpperPart = styled.div`
  .img-container {
    width: 100%;
    height: 320px;
    padding: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
`;

const LoginPart = styled.div`
  line-height: 25px;
  background-color: #ffffff;
  max-width: 424px;
  padding: 0 15px;
  /* z-index: 9999; */
  /* position: absolute;
  left: 0;
  right: 0; */
  /* bottom: -3%; */
  border-radius: 10px 10px 0 0;

  h2 {
    font-size: 25px;
  }
  p {
    color: #868889;
  }
`;
const Wrapper = styled.div``;

export default LoginPageBP;
