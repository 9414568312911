import React from "react";
import { styled } from "styled-components";

function CustomInput(props) {
  const {
    name,
    type,
    placeholder,
    onChange,
    newClass,
    value,
    disabled,
    label,
    maxlength,
    readonly,
    pattern,
    onInput
  } = props;

 
  return (
    <InputWrapper>
     
      <input
        name={name}
        value={value}
        type={type}
        className={newClass}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        maxLength={maxlength}
        readOnly={readonly}
        pattern={pattern}
        autoComplete="off"
        onInput={onInput}
      />
      {label && <label>{label}</label>}
    </InputWrapper>
  );
}
const InputWrapper = styled.div`
  position: relative;
  label {
    font-size: 12px;
    color: #8f94a3;
    position: absolute;
    left: 6%;
    top: 22%;
    z-index: 999;
  }
  input {
    background: #F5F4F5;
    border-radius: 8px;
    width: 100%;
    max-width: 370px;
    box-sizing: border-box;
    font-size: 14px;
    max-height: 60px;
    margin:10px auto;
    padding: 24px;
    padding-top:40px;
    border: 0;
    outline: none ;
    font-weight: 500;
    &:focus{
      border: 0;
    }
  }
`;

export default CustomInput;
