import React, { useEffect, useState } from "react";
import CartIcon from "../../assets/images/Buy.svg";
import styled from "styled-components";
import { useAuthContext } from "../../context/AuthContext";
import ButtonBlock from "../../components/Buttons/ButtonBlock";
import { useCartContext } from "../../context/CartContext";
import CartItem from "../../components/CartItem";
import { useNavigate } from "react-router-dom";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import toast from "react-hot-toast";
import { banglaLoginErrorMessage, convertToBanglaNumber } from "../../helpers/convert";
import { useAppContext } from "../../context/AppContext";
import { useTranslation } from "react-i18next";
import AlertDialog from "../../components/AlertDialog";

function Cart() {
  const { customerLoginInfo,bpLoginInfo } = useAuthContext();
  const access_token = customerLoginInfo?.token?.access_token || bpLoginInfo?.token?.access_token;
  const { cartItems, cartTotal, saveToRedis,checkFirstOrder,discountedAmount } = useCartContext();
  const { lang } = useAppContext();
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [offerData, setOfferData] = useState(null);
  const [open, setOpen] = React.useState(false);


  let orderItems = [];
  cartItems.map((item) =>
    orderItems.push({ product_id: item.id, qty: item.amount })
  );
  const ordereData = {
    billing_address: "Townhall, Mymensing, 2200",
    orderItems,
  };

  const placeOrder = async () =>{
    try {
      const { data } = await axiosPrivate.post(`/orders/create`, ordereData, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      toast.dismiss();
      toast.success(data.message);
      saveToRedis([]);
      navigate("/order");
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message);
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    let httpStatus, httpStatusCode;
    try {
      const data = await axiosPrivate.post(
        `/orders/check-duplicate-order`,
        ordereData,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      if (data?.status) {
        httpStatus = data.status;
        httpStatusCode = data?.data.status_code;
      }
    } catch (error) {
      const error_message = banglaLoginErrorMessage(error?.response?.data?.message, lang);
      if(error_message){
        toast.dismiss();
        toast.error(error_message);
      }
    }


    if (httpStatus == 200 && httpStatusCode == 200) {
      placeOrder();
    } else if (httpStatus == 200 && httpStatusCode == 409) {
      setOpen(true);
    }

  };

  useEffect(() => {
    checkFirstOrder();
  }, []);

  return (
    <Wrapper>
       <AlertDialog open={open} setOpen={setOpen} placeOrder={placeOrder} />
      <Header>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="backbutton"
            onClick={() => navigate(-1)}
            style={{ marginRight: "10px", cursor: "pointer" }}
          >
            <KeyboardBackspaceIcon />
          </div>
          <h3>Cart</h3>
        </div>
        <CartContainer>
          <img src={CartIcon} alt="Cart" />
          <p className="cart-amount">{cartItems?.length}</p>
        </CartContainer>
      </Header>
      {cartItems?.length === 0 ? (
        <div className="empty-cart">
          <h2>Your Cart is Empty</h2>
        </div>
      ) : (
        <>
          <FeatureProduct>
            {cartItems?.map((item) => {
              return <CartItem key={item.id} {...item} />;
            })}
          </FeatureProduct>

          <Total>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={3}>{t("sub_total")}</TableCell>
                  <TableCell align="right">
                    {lang?.checkedA
                      ? "৳" + convertToBanglaNumber(cartTotal.toFixed(2))
                      : cartTotal.toFixed(2) + " TK"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={3}>{t("discount")}</TableCell>
                  <TableCell align="right">
                    {lang?.checkedA
                      ? "- ৳" +
                        convertToBanglaNumber(discountedAmount?.toFixed(2))
                      : "-" + discountedAmount?.toFixed(2) + "TK"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ borderBottom: "none" }} colSpan={3}>
                    <h4 style={{ fontSize: "14px" }}>{t("total")}</h4>
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} align="right">
                    {" "}
                    <h4 style={{ fontSize: "14px" }}>
                      {lang?.checkedA
                        ? "৳" +
                          convertToBanglaNumber(
                            (cartTotal - discountedAmount)?.toFixed(2)
                          )
                        : (cartTotal - discountedAmount)?.toFixed(2) + " Tk"}
                    </h4>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Total>
          <form onSubmit={handleSubmit}>
            <ButtonBlock> {t("order_button_text")}</ButtonBlock>
          </form>
        </>
      )}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  .empty-cart {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }
`;

const FeatureProduct = styled.div`
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  h2 {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 20px 10px;
`;

const CartContainer = styled.section`
  position: relative;

  .cart-amount {
    position: absolute;
    top: -30%;
    right: -40%;
    border: 1px solid;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    background-color: #ee3d3c;
    color: white;
  }
`;

const Total = styled.div`
  padding: 15px;
  height: 195px;
  border: 1px solid #ebebeb;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to bottom, #f4f5f9, #ffffff);
  margin: 20px 0;
  color: #868889;
`;

export default Cart;
