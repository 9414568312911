import React from "react";
import styled from "styled-components";
import OTPInput from "react-otp-input";
function OtpBox(props) {
  const { otp, setOtp, length, name, pinError } = props;

  return (
    <Wrapper>
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={length}
        renderSeparator={false}
        renderInput={(props) => <input {...props} />}
        inputType="tel"
        shouldAutoFocus={name === "verify_otp" && true}
        inputStyle={pinError && {border:"1px solid red"}}
      />
    </Wrapper>
  );
}
export default OtpBox;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0 10px 0;
  input {
    text-align: center;
    width: 55px !important;
    height: 55px;
    padding: 10px;
    margin: 5px;
    border: 1px solid #8f94a3;
    outline: 0;
    background-color: #F5F4F5;
    font-size: 20px;
    font-weight: 600;
  }
`;
